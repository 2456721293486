import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PageBanner from "../components/page-banner"
import NewsContent from "../components/news-content"
import NewsList from "../components/news-list"
import HomeContact from "../components/home-contact"

const Page = ({ data, pageContext }) => {
  const posts = data.posts.nodes
  const mainPage = data.mainPage

  return (
    <Layout>
      <SEO post={mainPage} />
      <PageBanner post={mainPage} />
      <NewsContent>
        <NewsList posts={posts} pageContext={pageContext} />
      </NewsContent>
      <HomeContact />
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query($skip: Int!, $limit: Int!) {
    posts: allWordpressNews(
      filter: {status: {eq: "publish"}},
      limit: $limit,
      skip: $skip,
      sort: {order: DESC, fields: date_gmt}
    ) {
      nodes {
        ...NewsItem
      }
    }

    mainPage: wordpressMainPage(name: {eq: "news-superbom"}) {
      ...MainPageFull
    }
  }
`
